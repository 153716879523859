import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

function HeroText() {
  const StyledOutterWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    [theme.breakpoints.up("md")]: {
      width: "900px",
    },
  }));

  const StyledTitleWrapper = styled(Box)(({ theme }) => ({
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "min-content",
    },
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
  }));

  const StyledTitle = styled(Typography)(({ theme }) => ({
    WebkitTextStrokeWidth: "2px",
    WebkitTextStrokeColor: "black",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      WebkitTextStrokeWidth: "1px",
      fontFamily: "'Press Start 2P'",
      fontSize: "29px",
      lineHeight: "50px",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "'Press Start 2P'",
      fontSize: "48px",
      lineHeight: "70px",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "'Press Start 2P'",
      fontSize: "48px",
      lineHeight: "80px",
    },
  }));

  const StyledBr = styled("br")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: { display: "block" },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }));

  const StyledSecondTitle = styled(Typography)(({ theme }) => ({
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "black",
    textAlign: "center",
    padding: "5px",

    [theme.breakpoints.down("sm")]: {
      fontFamily: "'Press Start 2P'",
      fontSize: "15px",
      lineHeight: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "'Press Start 2P'",
      fontSize: "24px",
      lineHeight: "60px",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "'Press Start 2P'",
      fontSize: "28px",
      lineHeight: "80px",
    },
  }));

  const StyledCiggyWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: "42%",
      right: "-5px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "32%",
      right: "60px",
    },
    [theme.breakpoints.up("md")]: {
      top: "0",
      right: "90px",
    },
  }));

  const StyledImage = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      margin: "4px",
    },
    [theme.breakpoints.up("sm")]: {
      transform: "scale(0.85)",
      margin: "12px 0px",
    },
    [theme.breakpoints.up("md")]: {
      transform: "0",
      margin: "12px 2px",
    },
  }));

  return (
    <StyledOutterWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <StyledTitleWrapper sx={{ position: "relative" }}>
        <StyledTitle variant="h1" component="h2" color="white">
          DANK <StyledBr />
          PI
          <span>
            <StyledImage alt="pig nose sticker" src="/images/pig_nose.png" />
          </span>
          <span>
            <StyledImage alt="pig nose sticker" src="/images/pig_nose.png" />
          </span>
          IES COLLECTION
        </StyledTitle>
        <StyledCiggyWrapper>
          <StyledImage alt="ciggy sticker" src="/images/cig.png" />
        </StyledCiggyWrapper>
      </StyledTitleWrapper>

      <StyledSecondTitle variant="h3" component="h2" color="#FFCACA">
        MORE INFO COMING SOON...
      </StyledSecondTitle>
    </StyledOutterWrapper>
  );
}

export default HeroText;
