import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Slider from "../../components/PiggySlider/PiggySlider";
import HeroText from "../../components/HeroText/HeroText";
import ResponsiveAppBar from "../../components/ButtonAppBar/ResponsiveAppBar";
import SocialButton from "../../components/SocialButton/SocialButton";

const ButtonList = () => (
  <Box style={{ position: "fixed", right: 0, top: "30%" }}>
    <SocialButton
      iconUrl="/images/opensea_icon.png"
      link="https://opensea.io/collection/dank-piggies-club"
    />
    <SocialButton
      iconUrl="/images/discord_icon.png"
      link="https://discord.gg/C8XqfRA9B4"
    />
    <SocialButton
      iconUrl="/images/twitter_icon.png"
      link="https://twitter.com/dank_piggies"
    />
  </Box>
);

export default function HomePage() {
  const StyledOutterWrapper = styled(Box)(({ theme }) => ({
    minHeight: "100vh",
    height: "fit-content",
    width: "100vw",
    backgroundColor: "#252525",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url("/images/Background.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% -30%",

    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "20% 100px",
      minHeight: "100vh",
    },
    [theme.breakpoints.up("sm")]: { backgroundPosition: "20% 200px" },
    [theme.breakpoints.up("md")]: {},
  }));

  return (
    <>
      <ResponsiveAppBar />
      <StyledOutterWrapper>
        <Box
          style={{
            minHeight: "20vh",
            height: "20%",
            width: "100vw",
            zIndex: 20,
          }}
        >
          <ButtonList />
        </Box>
        <Box
          style={{
            height: "30%",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          <HeroText />
        </Box>
        <Box
          style={{ height: "50%", width: "100vw", display: "flex", flex: 1 }}
        >
          <Slider />
        </Box>
      </StyledOutterWrapper>
    </>
  );
}
