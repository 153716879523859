import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Navbar } from "react-responsive-navbar-overlay";
import { styled } from "@mui/system";

const ResponsiveAppBar = () => {
  const StyledTitle = styled(Typography)(() => ({
    WebkitTextStrokeWidth: "0.5px",
    WebkitTextStrokeColor: "black",
    fontFamily: "'Press Start 2P'",
    fontSize: "12px",
  }));

  const navProps = {
    links: [
      {
        text: <StyledTitle>ABOUT</StyledTitle>,
        link: "/#",
      },
      {
        text: <StyledTitle>CONTACT</StyledTitle>,
        link: "/#",
      },
      {
        text: <StyledTitle>STORIES</StyledTitle>,
        link: "/#",
      },
    ],
    brand: (
      <Button variant="text" href="#" style={{ color: "#FFCACA" }}>
        <img alt="Logo" src="/images/logo.png" />
      </Button>
    ),
    style: {
      barStyles: {
        background: "#444",
      },
      sidebarStyles: {
        background: "#222",
        buttonColor: "white",
      },
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        position: "absolute",
        top: "0",
        zIndex: 30,
      }}
    >
      <Navbar
        {...navProps}
        backgroundColor="transparent"
        brand={navProps.brand}
        links={navProps.links}
      />
    </Box>
  );
};
export default ResponsiveAppBar;
