import React from "react";
import Marquee from "react-fast-marquee";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/system";

function PiggySlider() {
  const themeHook = useTheme();
  const piggyImages = [
    "/images/angel_piggy.png",
    "/images/astronut_piggy.png",
    "/images/cowboy_piggy.png",
    "/images/green_helmet_tradie.png",
    "/images/paycheck_piggy.png",
    "/images/piggy_the_pirate_captain.png",
    "/images/princess_piggy.png",
    "/images/the_one_and_only.png",
  ];
  const desktopHeight = 420;
  const mobileHeight = 230;

  const StyledDiv = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      margin: `0 -${mobileHeight / 10}px`,
    },
    [theme.breakpoints.up("md")]: {
      margin: `0 -${desktopHeight / 10}px`,
    },
  }));

  const StyledImage = styled("img")(({ theme }) => ({
    aspectRatio: 1,

    [theme.breakpoints.down("md")]: {
      height: `${mobileHeight}px`,
    },
    [theme.breakpoints.up("md")]: {
      height: `${desktopHeight}px`,
    },
  }));
  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        bottom: "20px",
        [themeHook.breakpoints.down("md")]: {
          height: `${mobileHeight}px`,
        },
        [themeHook.breakpoints.up("md")]: {
          height: `${desktopHeight}px`,
        },
        pointerEvents: "none",
      }}
    >
      <Marquee
        style={{ height: "100%", webkitTransform: "translate3d(0, 0, 0)" }}
        delay={1}
        speed={100}
        gradient={!isMobile}
        gradientColor={isMobile ? {} : ["0", "0", "0"]}
      >
        {piggyImages.map((url) => (
          <StyledDiv
            key={`child-${url.substring(
              url.lastIndexOf("/") + 1,
              url.lastIndexOf(".")
            )}`}
            style={{
              WebkitMaskImage: `url(${process.env.PUBLIC_URL}/images/piggy_slide_mask.png)`,
              maskImage: `url(${process.env.PUBLIC_URL}/images/piggy_slide_mask.png)`,
              WebkitMaskSize: "cover",
              aspectRatio: 1,
            }}
          >
            <StyledImage src={process.env.PUBLIC_URL + url} alt="Logo" />
          </StyledDiv>
        ))}
      </Marquee>
    </Box>
  );
}

export default PiggySlider;
