import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function SocialButton({ iconUrl, link }) {
  return (
    <Box>
      <Button
        style={{
          color: "#FFCACA",
          margin: "5px 0",
          width: "69px",
          height: "49px",
          background: "rgba(196, 196, 196, 0.2)",
          border: " 1px solid #000000",
          borderRadius: "20px 0px 0px 20px",
        }}
        href={link}
        target="_blank"
      >
        <img src={`${iconUrl}`} alt="Twitter Icon" />
      </Button>
    </Box>
  );
}

export default SocialButton;
